import { Component, Vue, Watch } from 'vue-property-decorator';
import AvailabilityRequest from '@/components/AvailabilityRequest/AvailabilityRequest.vue';

@Component<ExpertAvailabilityRequest>({
  components: {
    AvailabilityRequest,
  },
})
export default class ExpertAvailabilityRequest extends Vue {
  public $pageTitle = 'Deskundige beschikbaarheid aanvragen';

  protected requestVisibility: any = {
    showLastRequestedAvailability: true,
    showRequestedAvailability: true,
  }

  public mounted() {

  }

  protected initialize() {
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {

  }
}
